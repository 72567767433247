.selected-row {
    font-weight: 500;
    background-color: whitesmoke;
}

.updated-row {
    color: lightsalmon;
}

.highlighted-cell {
    color: white;
    background-color: #1890ff;
    padding: 3px;
    border: 1px solid #1890ff;
    /* border-radius: 5%; */
}

.long-text-cell {
    white-space: pre-wrap
}

.square {
    height: 25px;
    width: 25px;
    text-align: center;
}

.square-active {
    cursor: pointer;
    color: white;
    background-color: #1890ff;
}

.square-active:hover {
    background-color: #40a9ff
}

.square-inactive {
    cursor: pointer;
    color: #1f1f1f;
    background-color: #f0f0f0
}

.square-inactive:hover {
    background-color: #bfbfbf;
}

.square-disable {
    cursor: not-allowed;
    color: #bfbfbf;
    background-color: #f0f0f0
}

.square-active-notallow {
    cursor: pointer;
    color: white;
    background-color: #1890ff;
}

.square-inactive-notallow {
    cursor: pointer;
    color: #1f1f1f;
    background-color: #f0f0f0
}

