@import '~antd/dist/reset.css';

/* custom scrollbar */
::-webkit-scrollbar {
    width: 5px;
    height: 5px
}

::-webkit-scrollbar-track {
    background-color: transparent;
    margin-top: 10px;
    margin-bottom: 10px;
}
  
::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 5px;
    min-height: 50px;
    /* border: 0px solid transparent; */
    opacity: 0.1;
    background-clip: content-box;
}
  
::-webkit-scrollbar-thumb:hover {
    background-color: #a5a5a5;
}

.text-button {
    color: black;
    font-size: 14px;
    text-overflow: ellipsis;
    cursor: pointer;
    /* margin: 100 10 10 10 */
}

.text-button:hover {
    color: #108ee9;
}

.list-button {
    cursor: pointer;
    padding-left: 5px
}

.list-marked {
    font-weight: 700;
}

.list-button:hover {
    background-color: whitesmoke;
    border-radius: 5px;
}

.icon-button {
    cursor: pointer;
    color: black
}

.icon-button:hover {
    color: #108ee9;
}

.icon-button-disabled {
    cursor: not-allowed;
    color: darkgray
}

.icon-switch-button {
    cursor: pointer;
}

.icon-switch-button:hover {
    color: black;
}

.icon-switch-button-disabled {
    cursor: not-allowed;
    color: darkgray
}

.ant-carousel .slick-dots li button {
    background: #69b1ff;
}

.ant-carousel .slick-dots li.slick-active button {
    background: #69b1ff;
}

.image-selection > div.ant-select-selector {
    min-height: 55px;
}

.more-select {
    /* display: flex;
    justify-content: space-between; */
    /* margin-top: 10px;
    margin-bottom: 10px; */
    width: 150px;
    color: black;
    font-size: 15px;
    text-overflow: ellipsis;
    cursor: pointer;
}

.more-select:hover {
    background-color: whitesmoke;
    color: #108ee9;
}

.typo-link-button {
    color: black
}

.ant-switch-checked {
    border-radius: 0;
    background-color: green
}

.ant-checkbox-group {
    width: 100%;
    height: 30vh;
    overflow: scroll;
    /* font-size: 10px; */
}

.ant-checkbox-group-item {
    display: flex!important;
    margin-right: 0;
}

